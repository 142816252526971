<template>
  <div class="container-fluid">
    <div>
      <template slot="header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("FILES.FILES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right"></div>
        </div>
      </template>

      <file-list-table
        :key="renderKey"
        @onEditFile="openEditFileModal"
        @onDeleteFile="deleteFile"
        @onViewFile="openViewFileModal"
      />
    </div>

    <file-edit-form-modal
      v-if="file"
      :showModal="showEditFormModal"
      :fileData="file"
      @onCloseModal="closeModal"
    />

    <file-view-modal
      v-if="file"
      :showModal="showViewModal"
      :fileData="file"
      @onCloseModal="closeModal"
      @fileUpdated="refreshFile"
      @editFile="
        (id) => {
          closeModal(false);
          openEditFileModal(id);
        }
      "
      @deleteFile="
        (id) => {
          closeModal(false);
          deleteFile(id);
        }
      "
    />
  </div>
</template>
<script>
import { Button } from "element-ui";
import swal from "sweetalert2";
import { QUERY_ACTIONS_VIEW, QUERY_ACTIONS_EDIT } from "@/constants/common";
import FileListTable from "@/components/File/partials/FileListTable.vue";
import FileEditFormModal from "@/components/File/partials/FileEditFormModal.vue";
import FileViewModal from "@/components/File/partials/FileViewModal.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: {
    FileListTable,
    [Button.name]: Button,
    FileEditFormModal,
    FileViewModal,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    return {
      renderKey: 0,
      file: null,
      showAddFormModal: false,
      showEditFormModal: false,
      showViewModal: false,
    };
  },

  created() {
    const fileId = this.$route.query.id;
    const action = this.$route.query.action;
    if (fileId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        this.openViewFileModal(fileId);
      } else if (action === QUERY_ACTIONS_EDIT) {
        this.openEditFileModal(fileId);
      }
    }
  },

  methods: {
    async openEditFileModal(id) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("files/get", id);
      this.file = this.$store.getters["files/file"];
      this.showEditFormModal = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Files",
          query: { id: this.file.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
      swal.close();
    },

    async openViewFileModal(id) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("files/get", id);
      this.file = this.$store.getters["files/file"];
      this.showViewModal = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Files",
          query: { id: this.file.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
      swal.close();
    },

    closeModal(refresh = false) {
      this.showAddFormModal = false;
      this.showEditFormModal = false;
      this.showViewModal = false;
      this.file = null;
      if (refresh) {
        this.renderKey = this.renderKey + 1;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Files",
          query: {},
        }).href
      );
    },

    async refreshFile() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      await this.$store.dispatch("files/get", this.file.id);
      this.file = this.$store.getters["files/file"];
      swal.close();
    },

    async deleteFile(id) {
      const confirmation = await swal.fire({
        title: this.$t("FILES.DELETE_THIS_FILE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          swal.fire({
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              swal.showLoading();
            },
          });
          await this.$store.dispatch("files/destroy", id);
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("FILES.FILE_DELETED"),
          });
          this.closeModal(true);
          swal.close();
        }
      } catch (error) {
        this.showRequestError(error);
      }
    },
  },
};
</script>
